<template>
  <SplitPage v-if="authorization && step">
    <template #header>
      <AuthorizationBreadcrumb :active-step="2"/>
    </template>

    <template #left-top>
      <StepsTabs/>
    </template>

    <template #left-content>
      <StepBlock v-if="previousStep"
                 :is-previous-step="true"
                 @delete-step-from-database="deletePreviousStepFromDatabase"
                 :authorization="authorization"
                 :step="previousStep"
                 :map="map"/>
      <StepBlock @delete-step-from-database="deleteCurrentStepFromDatabase"
                 :authorization="authorization"
                 :step="step"
                 :map="map"/>
      <StepDetails :step="step"/>
      <button @click="updateStep(step, true)"
              class="btn btn-block btn-secondary">
        Valider et voir l'étape
      </button>
      <button @click="updateStep(step, false)"
              class="btn btn-block btn-secondary">
        Valider et voir les étapes
      </button>
      <button @click="cancel"
              class="btn btn-block btn-outline-secondary">
        Annuler
      </button>
    </template>

    <template #right-content>
      <AuthorizationMap @map-ready="onMapReady"
                        :view-step="step"
                        :center="center"/>
    </template>
  </SplitPage>
</template>

<script>
import { clone } from '@/utils'
import SplitPage from '@/components/SplitPage'
import AuthorizationBreadcrumb from '@/components/AuthorizationBreadcrumb'
import StepsTabs from '@/components/StepsTabs'
import StepBlock from '@/components/StepBlock'
import StepDetails from '@/components/StepDetails'
import AuthorizationMap from '@/components/AuthorizationMap'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'AuthorizationsStepsEditPage',
  components: {
    SplitPage,
    AuthorizationBreadcrumb,
    StepsTabs,
    StepBlock,
    StepDetails,
    AuthorizationMap
  },
  data () {
    return {
      map: null
    }
  },
  computed: {
    ...mapState({
      authorization: state => state.authorization.authorization,
      steps: state => state.authorization.steps
    }),
    previousStep () {
      if (this.step && this.step.properties.num_step > 1) {
        return this.steps[this.step.properties.num_step - 2]
      }
      return null
    },
    step () {
      const step = this.steps.length ? this.steps[this.$route.params.num_step - 1] : null
      if (step) {
        step.properties.draggable = true
      }
      return step
    },
    center () {
      return [this.step.properties.latitude, this.step.properties.longitude]
    }
  },
  methods: {
    ...mapActions({
      getAuthorization: 'authorization/getAuthorization',
      getSteps: 'authorization/getSteps',
      resetState: 'authorization/resetState',
      removeStep: 'authorization/removeStep',
      saveSteps: 'authorization/saveSteps'
    }),
    onMapReady (map) {
      this.map = map
    },
    deletePreviousStepFromDatabase (previousStep) {
      const previousStepNum = previousStep.properties.num_step
      if (window.confirm('Êtes-vous sûr de vouloir supprimer ce point ? Cette action est irreversible.')) {
        this.removeStep(previousStep).then(() => {
          this.saveSteps().then(() => {
            // we have deleted the previous step, and the current step index
            // has changed. We must redirect the user to the current step index
            this.$router.push({
              name: 'authorizations.steps.edit',
              params: {
                id: this.$route.params.id,
                num_step: previousStepNum
              }
            })
          })
        })
      }
    },
    deleteCurrentStepFromDatabase () {
      if (window.confirm('Êtes-vous sûr de vouloir supprimer ce point ? Cette action est irreversible.')) {
        this.removeStep(this.step).then(() => {
          this.saveSteps().then(() => {
            // we redirect the user to the previous page
            this.$router.push({
              name: 'authorizations.steps.index',
              params: {
                id: this.$route.params.id
              }
            })
          })
        })
      }
    },
    updateStep (step, next_page_is_step_or_steps) {
      // As we save all the steps... we do not need step parameter...
      this.saveSteps().then(() => {
        // we redirect the user to the previous page
        if (next_page_is_step_or_steps) {
          this.$router.push({
            name: 'authorizations.steps.show',
            params: {
              id: this.$route.params.id,
              num_step: parseInt(this.$route.params.num_step)
            }
          })
        } else {
          this.$router.push({
            name: 'authorizations.steps.index',
            params: {
              id: this.$route.params.id
            }
          })
        }
      })
    },
    cancel () {
      this.$router.push({
        name: 'authorizations.steps.index',
        params: {
          id: this.$route.params.id
        }
      })
    }
  },
  mounted () {
    if (this.$route.params.num_step === undefined
      || this.$route.params.num_step === null) {
      this.$router.push({ name: '404' })
    }
    this
      .getAuthorization(this.$route.params.id)
      .then(() => this.getSteps(this.$route.params.id))
      .catch(() => this.$router.push({ name: '404' }))
  },
  beforeDestroy () {
    this.resetState()
  }
}
</script>
